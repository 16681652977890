<template>
  <div class="g-wrap my-wrap">
    <div class="list">
      <school-card :cardList="list" />
      <div class="loading">
        <div v-if="noMore" class="color-light">—— 没有更多了 ——</div>
        <el-button
          v-else
          @click="load"
          plain
          type="primary"
          :loading="loading"
          >{{ loading ? "加载中" : "加载更多" }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SchoolCard from "@/components/Common/SchoolCard";
import { getPageList } from "@/api/schoolInfo";
export default {
  name: "cooperation-school",
  components: {
    SchoolCard
  },
  data() {
    return {
      loading: false,
      list: [],
      pageIndex: 0,
      KeyWord: ""
    };
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    this.pageIndex = 0;
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.pageIndex++;
      var query = {
        PageSize: 8,
        PageIndex: this.pageIndex,
        KeyWord: this.KeyWord,
        Status: 2
      };
      getPageList(query).then(res => {
        if (res.IsSuccess) {
          this.list = this.list.concat(res.Result.Data);
          this.totalCount = res.Result.TotalCount;
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.g-wrap {
  .list {
    margin-top: 24px;
    width: 100%;
    .loading {
      margin: 8px 0 24px 0;
      text-align: center;
    }
  }
}
.my-wrap {
  width: 1148px;
  justify-content: center;
}
</style>
