<template>
  <div class="card-list">
    <div
      class="card-item cursor"
      v-for="(item, index) in cardList"
      :key="index"
      @click="handleClick(item)"
    >
      <el-image style="width:72px; height: 72px;" :src="item.Logo"></el-image>
      <h3>{{ item.EnterpriseName }}</h3>
      <div class="tags">{{ `${item.IndustryName} | ${item.NatureName}` }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyCard",
  props: {
    cardList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(item) {
      console.log("item: ", item);
      let routeUrl = this.$router.resolve({
        path: "/cooperation/home-enterprise/info",
        query: { id: item.Id }
      });
      window.open(routeUrl.href, "/path");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
