<template>
  <div class="cooperation">
    <div class="banner"></div>
    <div class="g-wrap">
      <div class="g-tabs">
        <router-link
          v-for="item in tabList"
          :key="item.id"
          :to="`/cooperation/` + item.id"
          :class="{ active: item.id == activeTab }"
        >
          {{ item.text }}
        </router-link>
      </div>
    </div>
    <component :is="activeComponent"></component>
  </div>
</template>

<script>
import enterprise from "./components/Index/Enterprise";
import school from "./components/Index/School";
export default {
  data() {
    return {
      tabList: [
        {
          id: "enterprise",
          component: enterprise,
          text: "企业单位"
        },
        {
          id: "school",
          component: school,
          text: "院校单位"
        }
      ],
      activeTab: "show",
      activeComponent: enterprise
    };
  },
  components: {
    enterprise,
    school
  },
  watch: {
    $route() {
      this.init();
    }
  },
  methods: {
    init() {
      const path = this.$route.path.split("/");
      const id = path[path.length - 1];
      const matched = this.tabList.filter(item => item.id === id);
      if (matched && matched.length) {
        this.activeTab = matched[0].id;
        this.activeComponent = matched[0].component;
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.cooperation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  .banner {
    background: url("~@/assets/images/banner/information.png") no-repeat center
      top;
    background-size: 100% 100%;
    height: 160px;
  }
  .g-wrap {
    position: relative;
    margin: 0 auto;
  }
}
</style>
