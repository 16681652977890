<template>
  <div class="g-wrap my-wrap">
    <!-- <div
      :class="`${isExpand ? 'condition-section' : 'condition-section-hide'}`"
    >
      <div class="job-condition">
        <span class="job-condition-title">薪资要求:</span>
        <div class="job-condition-wrap">
          <span
            class="job-condition-item"
            v-bind:class="{ checked: currentSalary === '' }"
            @click="handleChange(0, '')"
            >全部</span
          >
          <span
            v-for="{ label, value } in SalaryOptions"
            :key="value"
            class="job-condition-item"
            @click="handleChange(0, value)"
            v-bind:class="{
              checked: currentSalary !== '' && currentSalary == value
            }"
            >{{ label }}</span
          >
        </div>
      </div>
      <div class="job-condition">
        <span class="job-condition-title ">工作经验:</span>
        <div class="job-condition-wrap ">
          <span
            class="job-condition-item"
            v-bind:class="{ checked: currentExperience === '' }"
            @click="handleChange(1, '')"
            >全部</span
          >
          <span
            v-for="{ label, value } in ExperienceOptions"
            :key="value"
            class="job-condition-item"
            @click="handleChange(1, value)"
            v-bind:class="{
              checked: currentExperience !== '' && currentExperience == value
            }"
            >{{ label }}</span
          >
        </div>
      </div>
      <div class="job-condition">
        <span class="job-condition-title">人才学历:</span>
        <div class="job-condition-wrap">
          <span
            class="job-condition-item"
            v-bind:class="{ checked: currentEducation === '' }"
            @click="handleChange(2, '')"
            >全部</span
          >
          <span
            v-for="{ label, value } in EducationOptions"
            :key="value"
            class="job-condition-item"
            @click="handleChange(2, value)"
            v-bind:class="{
              checked: currentEducation !== '' && currentEducation == value
            }"
            >{{ label }}</span
          >
        </div>
      </div>
    </div> -->
    <div class="list">
      <company-card :cardList="list" />
      <div class="loading">
        <div v-if="noMore" class="color-light">—— 没有更多了 ——</div>
        <el-button
          v-else
          @click="load"
          plain
          type="primary"
          :loading="loading"
          >{{ loading ? "加载中" : "加载更多" }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CompanyCard from "@/components/Common/CompanyCard";
import { getPageList } from "@/api/enterprise/enterpriseInfo";
export default {
  name: "cooperation-enterprise",
  components: {
    CompanyCard
  },
  data() {
    return {
      loading: false,
      list: [],
      pageIndex: 0,
      KeyWord: "",
      isExpand: true,
      currentSalary: "",
      currentExperience: "",
      currentEducation: "",
      SalaryOptions: [
        { label: "不限", value: 0 },
        { label: "5-10K", value: 1 },
        { label: "10-15K", value: 2 }
      ],
      ExperienceOptions: [],
      EducationOptions: []
    };
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    this.pageIndex = 0;
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.pageIndex++;
      var query = {
        PageSize: 8,
        PageIndex: this.pageIndex,
        KeyWord: this.KeyWord,
        Status: 2
      };
      getPageList(query).then(res => {
        if (res.IsSuccess) {
          this.list = this.list.concat(res.Result.Data);
          this.totalCount = res.Result.TotalCount;
          this.loading = false;
          console.log(this.totalCount, this.list.length);
        }
      });
    },
    handleChange(type, value) {
      let needRefresh = false;
      switch (type) {
        case 0:
          if (this.currentSalary !== value) {
            needRefresh = true;
            this.currentSalary = value;
          }
          break;
        case 1:
          if (this.currentExperience !== value) {
            needRefresh = true;
            this.currentExperience = value;
          }
          break;
        case 2:
          if (this.currentEducation !== value) {
            needRefresh = true;
            this.currentEducation = value;
          }
          break;
        default:
          break;
      }
      if (needRefresh) {
        this.search();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.g-wrap {
  .list {
    margin-top: 24px;
    width: 100%;
    .loading {
      margin: 8px 0 24px 0;
      text-align: center;
    }
  }
}
.my-wrap {
  width: 1148px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.condition-section {
  width: 1100px;
  flex-direction: column;
  padding-top: 0px;
}
.talent-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.talent-item {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #eee;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.item-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.condition-section-hide {
  display: none;
}
.job-condition {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .job-condition-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .job-condition-wrap {
    display: flex;
    .job-condition-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      font-size: 14px;
      &:hover {
        background-color: #598DF3;
        color: #ffffff;
      }
    }
    .checked {
      background-color: #598DF3;
      color: #ffffff;
    }
  }
}
.job-condition-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sel-condition {
  border: 1px solid #b3d8ff;
  background-color: #ecf5ff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  .sel-condition-title {
    color: #598DF3;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.job-title {
  color: #598DF3;
  font-size: 18px;
}
.table-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-collect {
  color: #598DF3;
  font-size: 18px;
  cursor: pointer;
}
.dialog-content {
  height: 400px;
  display: flex;
  padding: 38px 28px;
  flex-direction: column;
  overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: "";
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598DF3;
      margin-right: 5px;
    }
  }
}
</style>
